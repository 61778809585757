import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue";
const _hoisted_1 = { class: "sampling" };
const _hoisted_2 = {
    key: 0,
    class: "tags"
};
const _hoisted_3 = {
    key: 1,
    class: "column-description"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_SubModule = _resolveComponent("SubModule");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_SubModule, {
            title: _ctx.$t('sub-modules.processes.name'),
            icon: "apps",
            hasData: _ctx.hasData,
            noDataMsg: _ctx.$t('loady2go.no-data')
        }, {
            default: _withCtx(() => [
                (_ctx.processes?.processes?.length)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.processes.processes, (key, index) => {
                            return (_openBlock(), _createElementBlock("span", {
                                key: key + index,
                                class: "tag"
                            }, _toDisplayString(_ctx.getValue(key, "Processes")), 1));
                        }), 128))
                    ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t("sub-modules.processes.components.display-processes.empty")), 1))
            ]),
            _: 1
        }, 8, ["title", "hasData", "noDataMsg"])
    ]));
}
