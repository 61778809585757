import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = { class: "vehicle" };
const _hoisted_2 = { class: "column-header" };
const _hoisted_3 = {
    key: 0,
    class: "chip-container"
};
const _hoisted_4 = {
    key: 1,
    class: "column-description"
};
const _hoisted_5 = { class: "column-header" };
const _hoisted_6 = {
    key: 0,
    class: "chip-container"
};
const _hoisted_7 = {
    key: 1,
    class: "column-description"
};
const _hoisted_8 = { class: "column-header" };
const _hoisted_9 = {
    key: 0,
    class: "chip-container"
};
const _hoisted_10 = {
    key: 1,
    class: "column-description"
};
const _hoisted_11 = { class: "column-header" };
const _hoisted_12 = {
    key: 0,
    class: "chip-container"
};
const _hoisted_13 = {
    key: 1,
    class: "column-description"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_SubmoduleField = _resolveComponent("SubmoduleField");
    const _component_LocalisedField = _resolveComponent("LocalisedField");
    const _component_SubModule = _resolveComponent("SubModule");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_SubModule, {
            title: _ctx.$t('sub-modules.vehicle.name'),
            icon: "local_shipping",
            hasData: _ctx.hasData,
            noDataMsg: _ctx.$t('sub-modules.vehicle.no-special-requirements')
        }, {
            default: _withCtx(() => [
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.vehicle.vehicleTypeRequired?.length
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("sub-modules.vehicle.vehicle-type-required")), 1),
                        (_ctx.vehicle.vehicleTypeRequired?.length)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTranslatedValues(_ctx.vehicle.vehicleTypeRequired, 'VehicleType'), (item) => {
                                    return (_openBlock(), _createElementBlock("span", {
                                        key: item,
                                        class: "chip"
                                    }, _toDisplayString(item), 1));
                                }), 128))
                            ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_4, "-"))
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.vehicle.vehicleTypeExcluded?.length
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("sub-modules.vehicle.vehicle-type-excluded")), 1),
                        (_ctx.vehicle.vehicleTypeExcluded?.length)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTranslatedValues(_ctx.vehicle.vehicleTypeExcluded, 'VehicleType'), (item) => {
                                    return (_openBlock(), _createElementBlock("span", {
                                        key: item,
                                        class: "chip"
                                    }, _toDisplayString(item), 1));
                                }), 128))
                            ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_7, "-"))
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.vehicle.vehicleSafetyEquipment?.length
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t("sub-modules.vehicle.vehicle-safety-equipment")), 1),
                        (_ctx.vehicle.vehicleSafetyEquipment?.length)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTranslatedValues(_ctx.vehicle.vehicleSafetyEquipment, 'VehicleSafetyEquipment'), (item) => {
                                    return (_openBlock(), _createElementBlock("span", {
                                        key: item,
                                        class: "chip"
                                    }, _toDisplayString(item), 1));
                                }), 128))
                            ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_10, "-"))
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.vehicle.chassis?.length
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t("sub-modules.vehicle.chassi")), 1),
                        (_ctx.vehicle.chassis?.length)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTranslatedValues(_ctx.vehicle.chassis, 'Chassis'), (item) => {
                                    return (_openBlock(), _createElementBlock("span", {
                                        key: item,
                                        class: "chip"
                                    }, _toDisplayString(item), 1));
                                }), 128))
                            ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_13, "-"))
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: _ctx.hasLocalisedText(_ctx.vehicle.additionalRequirements)
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_LocalisedField, {
                            modelValue: _ctx.vehicle?.additionalRequirements
                        }, {
                            header: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t("sub-modules.vehicle.additional-vehicle-requirements")), 1)
                            ]),
                            _: 1
                        }, 8, ["modelValue"])
                    ]),
                    _: 1
                }, 8, ["hasData"])
            ]),
            _: 1
        }, 8, ["title", "hasData", "noDataMsg"])
    ]));
}
