import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-e689faa2"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "temperature-requirements" };
const _hoisted_2 = { class: "column-header" };
const _hoisted_3 = {
    key: 0,
    class: "column-description"
};
const _hoisted_4 = {
    key: 1,
    class: "column-description"
};
const _hoisted_5 = { class: "column-header" };
const _hoisted_6 = { key: 0 };
const _hoisted_7 = { class: "temp-range" };
const _hoisted_8 = {
    key: 0,
    class: "date-range"
};
const _hoisted_9 = {
    key: 1,
    class: "column-description"
};
const _hoisted_10 = { class: "column-header" };
const _hoisted_11 = { key: 0 };
const _hoisted_12 = { class: "temp-range" };
const _hoisted_13 = {
    key: 0,
    class: "date-range"
};
const _hoisted_14 = {
    key: 1,
    class: "column-description"
};
const _hoisted_15 = { class: "column-header" };
const _hoisted_16 = { key: 0 };
const _hoisted_17 = { class: "temp-range" };
const _hoisted_18 = {
    key: 0,
    class: "date-range"
};
const _hoisted_19 = {
    key: 1,
    class: "column-description"
};
const _hoisted_20 = { class: "column-header" };
const _hoisted_21 = { class: "column-description" };
const _hoisted_22 = { class: "column-header" };
const _hoisted_23 = { key: 0 };
const _hoisted_24 = { class: "temp-range" };
const _hoisted_25 = {
    key: 0,
    class: "date-range"
};
const _hoisted_26 = {
    key: 1,
    class: "column-description"
};
const _hoisted_27 = { class: "column-header" };
const _hoisted_28 = { class: "column-description" };
const _hoisted_29 = { class: "column-header" };
const _hoisted_30 = { key: 0 };
const _hoisted_31 = {
    key: 1,
    class: "column-description"
};
const _hoisted_32 = { class: "column-header" };
const _hoisted_33 = { class: "column-description" };
const _hoisted_34 = { class: "column-header" };
const _hoisted_35 = { key: 0 };
const _hoisted_36 = { class: "temp-range" };
const _hoisted_37 = {
    key: 0,
    class: "date-range"
};
const _hoisted_38 = {
    key: 1,
    class: "column-description"
};
const _hoisted_39 = { class: "column-header" };
const _hoisted_40 = { key: 0 };
const _hoisted_41 = { class: "temp-range" };
const _hoisted_42 = {
    key: 0,
    class: "date-range"
};
const _hoisted_43 = {
    key: 1,
    class: "column-description"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_SubmoduleField = _resolveComponent("SubmoduleField");
    const _component_Icon = _resolveComponent("Icon");
    const _component_LocalisedField = _resolveComponent("LocalisedField");
    const _component_SubModule = _resolveComponent("SubModule");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_SubModule, {
            title: _ctx.$t('sub-modules.temperature-requirements.name'),
            icon: "thermostat",
            hasData: _ctx.hasData,
            noDataMsg: _ctx.$t('sub-modules.temperature-requirements.no-special-requirements')
        }, {
            default: _withCtx(() => [
                _createVNode(_component_SubmoduleField, {
                    hasData: _ctx.temperatureRequirement.maxContactTemperature !== null
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("sub-modules.temperature-requirements.maximum-contact-temperature")), 1),
                        (_ctx.temperatureRequirement.maxContactTemperature !== null)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.temperatureInfo(_ctx.temperatureRequirement.maxContactTemperature, _ctx.temperatureRequirement.maxContactTemperatureUnit)), 1))
                            : (_openBlock(), _createElementBlock("div", _hoisted_4, "-"))
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !_ctx.checkIfEmpty('tankTemperatureAtTheTimeOfLoading')
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("sub-modules.temperature-requirements.tank-temperature-loading")), 1),
                        (!_ctx.checkIfEmpty('tankTemperatureAtTheTimeOfLoading'))
                            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.temperatureRequirement.tankTemperatureAtTheTimeOfLoading, (item, index) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                        key: `tankTemperatureAtTheTimeOfLoading[${index}]`,
                                        class: "column-description description-item"
                                    }, [
                                        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.temperatureRangeInfo(item, _ctx.temperatureRequirement.tankTemperatureAtTheTimeOfLoadingTemperatureUnit)), 1),
                                        (_ctx.isDateRangeValid(item.datesRange))
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                                _createVNode(_component_Icon, { name: "date_range" }),
                                                _createTextVNode(" " + _toDisplayString(_ctx.getDateRangeLabelFormat(item.datesRange)), 1)
                                            ]))
                                            : _createCommentVNode("", true)
                                    ]));
                                }), 128))
                            ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_9, "-"))
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !_ctx.checkIfEmpty('loadingTemperature')
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t("sub-modules.temperature-requirements.loading-temperature")), 1),
                        (!_ctx.checkIfEmpty('loadingTemperature'))
                            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.temperatureRequirement.loadingTemperature, (item, index) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                        key: `loadingTemperature[${index}]`,
                                        class: "column-description description-item"
                                    }, [
                                        _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.temperatureRangeInfo(item, _ctx.temperatureRequirement.loadingTemperatureUnit)), 1),
                                        (_ctx.isDateRangeValid(item.datesRange))
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                                _createVNode(_component_Icon, { name: "date_range" }),
                                                _createTextVNode(" " + _toDisplayString(_ctx.getDateRangeLabelFormat(item.datesRange)), 1)
                                            ]))
                                            : _createCommentVNode("", true)
                                    ]));
                                }), 128))
                            ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_14, "-"))
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !_ctx.checkIfEmpty('transportTemperature')
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t("sub-modules.temperature-requirements.transport-temperature")), 1),
                        (!_ctx.checkIfEmpty('transportTemperature'))
                            ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.temperatureRequirement.transportTemperature, (item, index) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                        key: `transportTemperature[${index}]`,
                                        class: "column-description description-item"
                                    }, [
                                        _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.temperatureRangeInfo(item, _ctx.temperatureRequirement.transportTemperatureUnit)), 1),
                                        (_ctx.isDateRangeValid(item.datesRange))
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                                                _createVNode(_component_Icon, { name: "date_range" }),
                                                _createTextVNode(" " + _toDisplayString(_ctx.getDateRangeLabelFormat(item.datesRange)), 1)
                                            ]))
                                            : _createCommentVNode("", true)
                                    ]));
                                }), 128))
                            ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_19, "-"))
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.temperatureRequirement.heatingDuringTransport
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.$t("sub-modules.temperature-requirements.heating-during-transport")), 1),
                        _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.getValue(_ctx.temperatureRequirement.heatingDuringTransport, "HeatingDuringTransport")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !_ctx.checkIfEmpty('heatingSettingsDuringTransport')
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.$t("sub-modules.temperature-requirements.heating-settings-during-transport")), 1),
                        (!_ctx.checkIfEmpty('heatingSettingsDuringTransport'))
                            ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.temperatureRequirement.heatingSettingsDuringTransport, (item, index) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                        key: `heatingSettingsDuringTransport[${index}]`,
                                        class: "column-description description-item"
                                    }, [
                                        _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.temperatureRangeInfo(item, _ctx.temperatureRequirement.heatingSettingsDuringTransportTemperatureUnit)), 1),
                                        (_ctx.isDateRangeValid(item.datesRange))
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                                                _createVNode(_component_Icon, { name: "date_range" }),
                                                _createTextVNode(" " + _toDisplayString(_ctx.getDateRangeLabelFormat(item.datesRange)), 1)
                                            ]))
                                            : _createCommentVNode("", true)
                                    ]));
                                }), 128))
                            ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_26, "-"))
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.temperatureRequirement.heatingType
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_27, _toDisplayString(_ctx.$t("sub-modules.temperature-requirements.heating-type")), 1),
                        _createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.getValue(_ctx.temperatureRequirement.heatingType, "HeatingType")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.filteredHeatingTemperatureRestrictions.length
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_29, _toDisplayString(_ctx.$t("sub-modules.temperature-requirements.max-heating-medium-temperature")), 1),
                        (!!_ctx.filteredHeatingTemperatureRestrictions.length)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredHeatingTemperatureRestrictions, (item, index) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                        key: `heatingMediumTemperatureRestrictions[${index}]`,
                                        class: "column-description description-item"
                                    }, _toDisplayString(`${_ctx.getValue(item.heatingMedium, "HeatingMedium")}: ${_ctx.temperatureInfo(item.maxHeatingMediumTemperature, item.maxHeatingMediumTemperatureUnit)}`), 1));
                                }), 128))
                            ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_31, "-"))
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.temperatureRequirement.coolingDuringTransport
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_32, _toDisplayString(_ctx.$t("sub-modules.temperature-requirements.cooling-during-transport")), 1),
                        _createElementVNode("div", _hoisted_33, _toDisplayString(_ctx.getValue(_ctx.temperatureRequirement.coolingDuringTransport, "CoolingDuringTransport")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !_ctx.checkIfEmpty('coolingSettingsDuringTransport')
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_34, _toDisplayString(_ctx.$t("sub-modules.temperature-requirements.cooling-settings-during-transport")), 1),
                        (!_ctx.checkIfEmpty('coolingSettingsDuringTransport'))
                            ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.temperatureRequirement.coolingSettingsDuringTransport, (item, index) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                        key: `coolingSettingsDuringTransport[${index}]`,
                                        class: "column-description description-item"
                                    }, [
                                        _createElementVNode("div", _hoisted_36, _toDisplayString(_ctx.temperatureRangeInfo(item, _ctx.temperatureRequirement.coolingSettingsDuringTransportTemperatureUnit)), 1),
                                        (_ctx.isDateRangeValid(item.datesRange))
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                                                _createVNode(_component_Icon, { name: "date_range" }),
                                                _createTextVNode(" " + _toDisplayString(_ctx.getDateRangeLabelFormat(item.datesRange)), 1)
                                            ]))
                                            : _createCommentVNode("", true)
                                    ]));
                                }), 128))
                            ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_38, "-"))
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !_ctx.checkIfEmpty('unloadingTemperature')
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_39, _toDisplayString(_ctx.$t("sub-modules.temperature-requirements.unloading-temperature")), 1),
                        (!_ctx.checkIfEmpty('unloadingTemperature'))
                            ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.temperatureRequirement.unloadingTemperature, (item, index) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                        key: `unloadingTemperature[${index}]`,
                                        class: "column-description description-item"
                                    }, [
                                        _createElementVNode("div", _hoisted_41, _toDisplayString(_ctx.temperatureRangeInfo(item, _ctx.temperatureRequirement.unloadingTemperatureUnit)), 1),
                                        (_ctx.isDateRangeValid(item.datesRange))
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
                                                _createVNode(_component_Icon, { name: "date_range" }),
                                                _createTextVNode(" " + _toDisplayString(_ctx.getDateRangeLabelFormat(item.datesRange)), 1)
                                            ]))
                                            : _createCommentVNode("", true)
                                    ]));
                                }), 128))
                            ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_43, "-"))
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: _ctx.hasLocalisedText(_ctx.temperatureRequirement.additionalTemperatureRequirements)
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_LocalisedField, {
                            modelValue: _ctx.temperatureRequirement?.additionalTemperatureRequirements
                        }, {
                            header: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t("sub-modules.temperature-requirements.additional-temperature-requirements")), 1)
                            ]),
                            _: 1
                        }, 8, ["modelValue"])
                    ]),
                    _: 1
                }, 8, ["hasData"])
            ]),
            _: 1
        }, 8, ["title", "hasData", "noDataMsg"])
    ]));
}
