import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-de5f4066"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "site-navigation" };
const _hoisted_2 = { class: "column-title" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_DisplayAttachments = _resolveComponent("DisplayAttachments");
    const _component_SubmoduleField = _resolveComponent("SubmoduleField");
    const _component_SubModule = _resolveComponent("SubModule");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_SubModule, {
            title: _ctx.$t('loady2go.sub-modules.site-navigation.name'),
            icon: "near_me",
            hasData: _ctx.hasData,
            noDataMsg: _ctx.$t('loady2go.no-data')
        }, {
            default: _withCtx(() => [
                _createVNode(_component_SubmoduleField, {
                    hasData: _ctx.hasLocalisedAttachment(_ctx.modelValue)
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("sub-modules.site-navigation.components.title")), 1),
                        _createVNode(_component_DisplayAttachments, {
                            attachments: _ctx.modelValue,
                            title: _ctx.$t('sub-modules.site-navigation.components.navigation-apps')
                        }, null, 8, ["attachments", "title"])
                    ]),
                    _: 1
                }, 8, ["hasData"])
            ]),
            _: 1
        }, 8, ["title", "hasData", "noDataMsg"])
    ]));
}
