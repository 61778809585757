import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-71836fdd"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "sealing" };
const _hoisted_2 = { class: "column-header" };
const _hoisted_3 = { class: "column-header" };
const _hoisted_4 = { class: "column-description" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LocalisedField = _resolveComponent("LocalisedField");
    const _component_SubmoduleField = _resolveComponent("SubmoduleField");
    const _component_SubModule = _resolveComponent("SubModule");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_SubModule, {
            title: _ctx.$t('sub-modules.sealing.name'),
            icon: "check_circle",
            hasData: _ctx.hasData,
            noDataMsg: _ctx.$t('loady2go.no-data')
        }, {
            default: _withCtx(() => [
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.sealing.sealingOfVehicleConnections
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("sub-modules.sealing.sealing-of-vehicle-connections")), 1),
                        _createElementVNode("div", {
                            class: _normalizeClass(["column-description", {
                                    isRequiredInSomeCases: _ctx.isRequiredInSomeCases &&
                                        (_ctx.hasLocalisedText(_ctx.sealing.sealingOfVehicleInSomeCases) || _ctx.$store.state.ui.showIfNoData),
                                }])
                        }, [
                            _createTextVNode(_toDisplayString(_ctx.getValue(_ctx.sealing.sealingOfVehicleConnections, "SealingOfVehicleConnections")) + " ", 1),
                            _createVNode(_component_LocalisedField, {
                                modelValue: _ctx.sealing.sealingOfVehicleInSomeCases
                            }, _createSlots({ _: 2 }, [
                                (_ctx.isRequiredInSomeCases)
                                    ? {
                                        name: "description",
                                        fn: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.$t("sub-modules.filling.additional-filling-requirements")), 1)
                                        ]),
                                        key: "0"
                                    }
                                    : undefined
                            ]), 1032, ["modelValue"])
                        ], 2)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.sealing.executedBy
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("sub-modules.sealing.sealing-executed-by")), 1),
                        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.getValue(_ctx.sealing.executedBy, "SealingIsDoneByWhom")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"])
            ]),
            _: 1
        }, 8, ["title", "hasData", "noDataMsg"])
    ]));
}
