import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = { class: "sampling" };
const _hoisted_2 = {
    key: 0,
    class: "column-header"
};
const _hoisted_3 = {
    key: 1,
    class: "column-header"
};
const _hoisted_4 = { class: "column-description" };
const _hoisted_5 = { class: "column-header" };
const _hoisted_6 = { class: "column-description" };
const _hoisted_7 = { class: "column-header" };
const _hoisted_8 = { class: "column-description" };
const _hoisted_9 = {
    key: 0,
    class: "column-header"
};
const _hoisted_10 = {
    key: 1,
    class: "column-header"
};
const _hoisted_11 = { class: "column-description" };
const _hoisted_12 = { class: "column-header" };
const _hoisted_13 = { class: "column-description" };
const _hoisted_14 = { class: "column-header" };
const _hoisted_15 = { class: "column-description" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_SubmoduleField = _resolveComponent("SubmoduleField");
    const _component_LocalisedField = _resolveComponent("LocalisedField");
    const _component_SubModule = _resolveComponent("SubModule");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_SubModule, {
            title: _ctx.$t('sub-modules.sampling.name'),
            icon: "science",
            hasData: _ctx.hasData,
            noDataMsg: _ctx.$t('loady2go.no-data')
        }, {
            default: _withCtx(() => [
                _createVNode(_component_SubmoduleField, {
                    hasData: _ctx.sampling.sampleDrawingBeforeUnloading !== null
                }, {
                    default: _withCtx(() => [
                        (_ctx.isLoadingPoint)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t("sub-modules.sampling.form-fields.loading-before")), 1))
                            : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t("sub-modules.sampling.form-fields.unloading-before")), 1)),
                        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.getTranslation(_ctx.sampling.sampleDrawingBeforeUnloading)), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.sampling.sampleDrawingBy
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("sub-modules.sampling.form-fields.drawing-by")), 1),
                        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.getValue(_ctx.sampling.sampleDrawingBy, "SampleDrawingBy")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.sampling.sampleDrawingFrom.length
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t("sub-modules.sampling.form-fields.drawing-from")), 1),
                        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.getValues(_ctx.sampling.sampleDrawingFrom, "SampleDrawingFrom")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: _ctx.sampling.sampleForLaboratoryTestingToReleaseUnloading !== null
                }, {
                    default: _withCtx(() => [
                        (_ctx.isLoadingPoint)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.$t("sub-modules.sampling.form-fields.lab-testing.loading-label")), 1))
                            : (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.$t("sub-modules.sampling.form-fields.lab-testing.unloading-label")), 1)),
                        _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.getTranslation(_ctx.sampling.sampleForLaboratoryTestingToReleaseUnloading)), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: _ctx.sampling.minimumWaitingTimeForTestResults > 0
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t("sub-modules.sampling.form-fields.lab-testing.description")), 1),
                        _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.numSingularOrPluralInfo(_ctx.sampling.minimumWaitingTimeForTestResults, "global.temporal.hours.label-single", "global.temporal.hours.label")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: _ctx.sampling.retainedSample !== null
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t("sub-modules.sampling.form-fields.retained.label")), 1),
                        _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.showRetainedSample), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: _ctx.hasLocalisedText(_ctx.sampling.additionalInformation)
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_LocalisedField, {
                            modelValue: _ctx.sampling?.additionalInformation
                        }, {
                            header: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t("global.phrases.additional-information")), 1)
                            ]),
                            _: 1
                        }, 8, ["modelValue"])
                    ]),
                    _: 1
                }, 8, ["hasData"])
            ]),
            _: 1
        }, 8, ["title", "hasData", "noDataMsg"])
    ]));
}
