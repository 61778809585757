import { createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-398525f0"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "tag" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _directive_tooltip = _resolveDirective("tooltip");
    return _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_1, [
        _createTextVNode("EN")
    ])), [
        [
            _directive_tooltip,
            _ctx.$t('modular-content.components.localised-tag.tooltip'),
            void 0,
            { bottom: true }
        ]
    ]);
}
