import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = { class: "connections" };
const _hoisted_2 = { class: "column-header" };
const _hoisted_3 = { class: "column-description" };
const _hoisted_4 = { class: "column-header" };
const _hoisted_5 = { class: "column-description" };
const _hoisted_6 = { class: "column-header" };
const _hoisted_7 = { class: "column-description" };
const _hoisted_8 = { class: "column-header" };
const _hoisted_9 = { class: "column-description" };
const _hoisted_10 = { class: "column-header" };
const _hoisted_11 = { class: "column-description" };
const _hoisted_12 = { class: "column-header" };
const _hoisted_13 = {
    key: 0,
    class: "chip-container"
};
const _hoisted_14 = {
    key: 1,
    class: "column-description"
};
const _hoisted_15 = { class: "column-header" };
const _hoisted_16 = { class: "column-description" };
const _hoisted_17 = { class: "column-header" };
const _hoisted_18 = { class: "column-description" };
const _hoisted_19 = { class: "column-header" };
const _hoisted_20 = { class: "column-description" };
const _hoisted_21 = { class: "column-header" };
const _hoisted_22 = { class: "column-description" };
const _hoisted_23 = { class: "column-header" };
const _hoisted_24 = { class: "column-description" };
const _hoisted_25 = { class: "column-header" };
const _hoisted_26 = { class: "column-description" };
const _hoisted_27 = { class: "column-header" };
const _hoisted_28 = { class: "column-description" };
const _hoisted_29 = { class: "column-header" };
const _hoisted_30 = { class: "column-description" };
const _hoisted_31 = { class: "column-header" };
const _hoisted_32 = { class: "column-description" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_TruckWidget = _resolveComponent("TruckWidget");
    const _component_SubmoduleField = _resolveComponent("SubmoduleField");
    const _component_LocalisedField = _resolveComponent("LocalisedField");
    const _component_SubModule = _resolveComponent("SubModule");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_SubModule, {
            title: _ctx.$t('sub-modules.connections.name'),
            icon: "cable",
            hasData: _ctx.hasData,
            noDataMsg: _ctx.$t('global.phrases.no-special-requirements')
        }, {
            default: _withCtx(() => [
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.connections.truckConnections.length
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("sub-modules.connections.truck-connections-title")), 1),
                        _createElementVNode("div", _hoisted_3, [
                            _createVNode(_component_TruckWidget, {
                                truckConnections: _ctx.connections.truckConnections
                            }, null, 8, ["truckConnections"])
                        ])
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: _ctx.connections.clearlyLabelledConnections !== null
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("sub-modules.connections.clearly-labelled-connections-title")), 1),
                        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.getTranslation(_ctx.connections.clearlyLabelledConnections, "required", "not-required")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: _ctx.connections.pointLockedConnection !== null
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t("sub-modules.connections.point-locked-connection-title")), 1),
                        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.getTranslation(_ctx.connections.pointLockedConnection, "required", "not-required")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.connections.connectionSealingIsDoneByWhom
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t("sub-modules.connections.connection-sealing-is-done-by-whom-title")), 1),
                        _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.getValue(_ctx.connections.connectionSealingIsDoneByWhom, "ConnectionSealingIsDoneByWhom")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: _ctx.connections.coupling !== null
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t("sub-modules.connections.coupling-title")), 1),
                        _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.getTranslation(_ctx.connections.coupling, "required", "not-required")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.connections.couplingTypes.length
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t("sub-modules.connections.coupling-type-label")), 1),
                        (_ctx.connections.couplingTypes.length)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTranslatedValues(_ctx.connections.couplingTypes, 'CouplingType'), (item) => {
                                    return (_openBlock(), _createElementBlock("span", {
                                        key: item,
                                        class: "chip"
                                    }, _toDisplayString(item), 1));
                                }), 128))
                            ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_14, "-"))
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.connections.couplingSize
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t("sub-modules.connections.coupling-size-label")), 1),
                        _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.getValue(_ctx.connections.couplingSize, "CouplingSize")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.connections.configuration
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.$t("sub-modules.connections.configuration")), 1),
                        _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.getValue(_ctx.connections.configuration, "ConnectionConfiguration")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: _ctx.hasLocalisedText(_ctx.connections.couplingAdditionalInformation)
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_LocalisedField, {
                            modelValue: _ctx.connections?.couplingAdditionalInformation
                        }, {
                            header: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t("global.phrases.additional-information")), 1)
                            ]),
                            _: 1
                        }, 8, ["modelValue"])
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: _ctx.connections.flange !== null
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t("sub-modules.connections.flange-title")), 1),
                        _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.getTranslation(_ctx.connections.flange, "required", "not-required")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.connections.flangeType
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.$t("sub-modules.connections.flange-type-label")), 1),
                        _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.getValue(_ctx.connections.flangeType, "FlangeType")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.connections.flangeSize
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.$t("sub-modules.connections.flange-size-label")), 1),
                        _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.getValue(_ctx.connections.flangeSize, "FlangeSize")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.connections.minimumStudBoltLength
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.$t("sub-modules.connections.minimum-stud-bolt-length-label")), 1),
                        _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.connections.minimumStudBoltLength || "-"), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: _ctx.hasLocalisedText(_ctx.connections.flangeAdditionalInformation)
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_LocalisedField, {
                            modelValue: _ctx.connections?.flangeAdditionalInformation
                        }, {
                            header: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t("global.phrases.additional-information")), 1)
                            ]),
                            _: 1
                        }, 8, ["modelValue"])
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: _ctx.connections.heating !== null
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_27, _toDisplayString(_ctx.$t("sub-modules.connections.heating-system-connection-title")), 1),
                        _createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.getTranslation(_ctx.connections.heating, "required", "not-required")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.connections.heatingType
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_29, _toDisplayString(_ctx.$t("sub-modules.connections.heating-system-connection-type-label")), 1),
                        _createElementVNode("div", _hoisted_30, _toDisplayString(_ctx.getValue(_ctx.connections.heatingType, "ConnectionHeatingType")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: _ctx.hasLocalisedText(_ctx.connections.heatingAdditionalInformation)
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_LocalisedField, {
                            modelValue: _ctx.connections?.heatingAdditionalInformation
                        }, {
                            header: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t("global.phrases.additional-information")), 1)
                            ]),
                            _: 1
                        }, 8, ["modelValue"])
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: _ctx.connections.adaptors !== null
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_31, _toDisplayString(_ctx.$t("sub-modules.connections.adaptors-title")), 1),
                        _createElementVNode("div", _hoisted_32, _toDisplayString(_ctx.getTranslation(_ctx.connections.adaptors, "required", "not-required")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: _ctx.hasLocalisedText(_ctx.connections.adaptorsAdditionalInformation)
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_LocalisedField, {
                            modelValue: _ctx.connections?.adaptorsAdditionalInformation
                        }, {
                            header: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t("global.phrases.additional-information")), 1)
                            ]),
                            _: 1
                        }, 8, ["modelValue"])
                    ]),
                    _: 1
                }, 8, ["hasData"])
            ]),
            _: 1
        }, 8, ["title", "hasData", "noDataMsg"])
    ]));
}
