import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = { class: "tank" };
const _hoisted_2 = { class: "column-header" };
const _hoisted_3 = { class: "column-description" };
const _hoisted_4 = { class: "column-header" };
const _hoisted_5 = { class: "column-description" };
const _hoisted_6 = { class: "column-header" };
const _hoisted_7 = {
    key: 0,
    class: "chip-container"
};
const _hoisted_8 = {
    key: 1,
    class: "column-description"
};
const _hoisted_9 = { class: "column-header" };
const _hoisted_10 = { class: "column-description" };
const _hoisted_11 = { class: "column-header" };
const _hoisted_12 = {
    key: 0,
    class: "chip-container"
};
const _hoisted_13 = {
    key: 1,
    class: "column-description"
};
const _hoisted_14 = { class: "column-header" };
const _hoisted_15 = {
    key: 0,
    class: "chip-container"
};
const _hoisted_16 = {
    key: 1,
    class: "column-description"
};
const _hoisted_17 = { class: "column-header" };
const _hoisted_18 = { class: "column-description" };
const _hoisted_19 = { class: "column-header" };
const _hoisted_20 = { class: "column-description" };
const _hoisted_21 = { class: "column-header" };
const _hoisted_22 = { class: "column-description" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_SubmoduleField = _resolveComponent("SubmoduleField");
    const _component_LocalisedField = _resolveComponent("LocalisedField");
    const _component_SubModule = _resolveComponent("SubModule");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_SubModule, {
            title: _ctx.$t('sub-modules.tank.name'),
            icon: "signal_wifi_statusbar_connected_no_internet_4",
            hasData: _ctx.hasData,
            noDataMsg: _ctx.$t('sub-modules.tank.no-special-requirements')
        }, {
            default: _withCtx(() => [
                _createVNode(_component_SubmoduleField, {
                    hasData: _ctx.tank.equipmentOperatedFromTheGround !== null
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("sub-modules.tank.equipment-operated")), 1),
                        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.getTranslation(_ctx.tank.equipmentOperatedFromTheGround, "required", "not-required")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.tank.numberOfCompartments
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("sub-modules.tank.number-of-compartments")), 1),
                        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.getValue(_ctx.tank.numberOfCompartments, "NumberOfCompartments")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.tank.tankMaterials.length
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t("sub-modules.tank.tank-material")), 1),
                        (_ctx.tank.tankMaterials.length)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTranslatedValues(_ctx.tank.tankMaterials, 'TankMaterial'), (item) => {
                                    return (_openBlock(), _createElementBlock("span", {
                                        key: item,
                                        class: "chip"
                                    }, _toDisplayString(item), 1));
                                }), 128))
                            ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_8, "-"))
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.tank.manhole
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t("sub-modules.tank.manhole")), 1),
                        _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.getValue(_ctx.tank.manhole, "Manhole")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.tank.insulations.length
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t("sub-modules.tank.insulation")), 1),
                        (_ctx.tank.insulations.length)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTranslatedValues(_ctx.tank.insulations, 'Insulation'), (item) => {
                                    return (_openBlock(), _createElementBlock("span", {
                                        key: item,
                                        class: "chip"
                                    }, _toDisplayString(item), 1));
                                }), 128))
                            ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_13, "-"))
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.tank.domeValveGasketMaterials.length
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t("sub-modules.tank.dome-valve-gasket-material")), 1),
                        (_ctx.tank.domeValveGasketMaterials.length)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTranslatedValues(_ctx.tank.domeValveGasketMaterials, 'DomeValveGasketMaterial'), (item) => {
                                    return (_openBlock(), _createElementBlock("span", {
                                        key: item,
                                        class: "chip"
                                    }, _toDisplayString(item), 1));
                                }), 128))
                            ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_16, "-"))
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.tank.dipTube
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.$t("sub-modules.tank.dip-tube")), 1),
                        _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.getValue(_ctx.tank.dipTube, "DipTube")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.tank.heatedDipTube
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t("sub-modules.tank.heated-dip-tube")), 1),
                        _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.getValue(_ctx.tank.heatedDipTube, "HeatedDipTube")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: _ctx.tank.nitrogenUnloading !== null
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.$t("sub-modules.tank.nitrogen")), 1),
                        _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.getTranslation(_ctx.tank.nitrogenUnloading, "required", "not-required")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: _ctx.hasLocalisedText(_ctx.tank.additionalRequirements)
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_LocalisedField, {
                            modelValue: _ctx.tank?.additionalRequirements
                        }, {
                            header: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t("sub-modules.tank.additional-tank-requirements")), 1)
                            ]),
                            _: 1
                        }, 8, ["modelValue"])
                    ]),
                    _: 1
                }, 8, ["hasData"])
            ]),
            _: 1
        }, 8, ["title", "hasData", "noDataMsg"])
    ]));
}
