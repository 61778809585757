import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue";
const _hoisted_1 = { class: "column" };
const _hoisted_2 = { class: "column-header gap-3 col-6" };
const _hoisted_3 = { class: "column-description col-6'" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LocalisedFieldTag = _resolveComponent("LocalisedFieldTag");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "header")
        ]),
        _createElementVNode("div", _hoisted_3, [
            (_ctx.freeText)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _renderSlot(_ctx.$slots, "description"),
                    (_ctx.isLocalisedFreeText.length)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass(["free-text", { 'mt-2': !_ctx.isEmpty }])
                        }, [
                            _createTextVNode(_toDisplayString(_ctx.getValue(_ctx.modelValue) ?? "-") + " ", 1),
                            (_ctx.showFallbackTag)
                                ? (_openBlock(), _createBlock(_component_LocalisedFieldTag, { key: 0 }))
                                : _createCommentVNode("", true)
                        ], 2))
                        : _createCommentVNode("", true)
                ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(_toDisplayString(_ctx.getValue(_ctx.modelValue) ?? "-") + " ", 1),
                    (_ctx.showFallbackTag)
                        ? (_openBlock(), _createBlock(_component_LocalisedFieldTag, { key: 0 }))
                        : _createCommentVNode("", true)
                ], 64))
        ])
    ]));
}
